import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/background.jpg";
import { useSelector, useDispatch } from "react-redux";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AlertDialog from '../components/AlertDialog';
import CountrySelect from '../components/CountrySelect';
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import CircularLoading from "../components/CircularLoading";

import kingsChatWebSdk from 'kingschat-web-sdk';

const useStyles = makeStyles(styles);

export default function LoginPageApp(props) {
  const { api, authRef, RecaptchaVerifier } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    kingschatwebSign,
    signIn,
    facebookSignIn,
    clearLoginError,
    mobileSignIn,
    countries,
    requestEmailOtp,
    verifyEmailOtp
  } = api;

  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();
  const [capatchaReady, setCapatchaReady] = React.useState(false);

  const [data, setData] = React.useState({
    email: '',
    pass: '',
    country: null,
    contact: '',
    password: '',
    otp: '',
    verificationId: null,
    firstName: '',
    lastName: '',
    selectedcountry:null,
    usertype:'rider',
    referralId:'',
    entryType: null
  });

  const [otpCalled, setOtpCalled] = useState();
  const [loading, setLoading] = useState(false);

  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });

  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    if(settings){
        for (let i = 0; i < countries.length; i++) {
            if(countries[i].label === settings.country){
                setData({
                  country: countries[i].phone,
                  selectedcountry:countries[i],
                });
            }
        }
    }
  }, [settings,countries]);

  useEffect(() => {
    if(!capatchaReady){
      window.recaptchaVerifier = new RecaptchaVerifier("sign-in-button",{
        'size': 'invisible',
        'callback': function(response) {
          setCapatchaReady(true);
        }
      });
    }
    if (auth.info) {
      if(auth.info.profile){
        let role = auth.info.profile.usertype;
        if(role==='admin' || role==='fleetadmin'){
          props.history.push('/dashboard');
        }
        else if (role==='driver'){
          props.history.push('/bookings');
        }
        else {
          props.history.push('/');
        }
      }
    }
    if (auth.error && auth.error.flag && auth.error.msg.message !== t('not_logged_in')) {
      if (auth.error.msg.message === t('require_approval')){
        setCommonAlert({ open: true, msg: t('require_approval') })
      } else{
        if(data.contact === '' ||  !(!data.contact) ){  
          setCommonAlert({ open: true, msg: t('login_error') })
        }
      }
    }
    if(auth.verificationId && otpCalled){
      setOtpCalled(false);
      setData({ ...data, verificationId: auth.verificationId });
    }
  }, [auth.info, auth.error, auth.verificationId, props.history, data, data.email, data.contact, capatchaReady,RecaptchaVerifier,t, setData, otpCalled, setOtpCalled]);




					(function(){

						var promiseChain = Promise.resolve();

						var callbacks = {};

						var init = function() {

							const guid = function() {
								function s4() {
									return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
								}
								return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
							}

							window.webViewBridge = {
								/**
								 * send message to the React-Native WebView onMessage handler
								 * @param targetFunc - name of the function to invoke on the React-Native side
								 * @param data - data to pass
								 * @param success - success callback
								 * @param error - error callback
								 */
								send: function(targetFunc, data, success, error) {

									var msgObj = {
										targetFunc: targetFunc,
										data: data || {}
									};

									if (success || error) {
										msgObj.msgId = guid();
									}

									var msg = JSON.stringify(msgObj);

									promiseChain = promiseChain.then(function () {
										return new Promise(function (resolve, reject) {
											console.log("sending message " + msgObj.targetFunc);

											if (msgObj.msgId) {
												callbacks[msgObj.msgId] = {
													onsuccess: success,
													onerror: error
												};
											}

											window.ReactNativeWebView.postMessage(msg);

											resolve();
										})
									}).catch(function (e) {
										window.document.getElementById("container").innerHTML = 'rnBridge send failed ' + e.message;
									});
								},


							};

							window.document.addEventListener('message', function(e) {
								console.log("message received from react native");

								var message;
								try {
									message = JSON.parse(e.data)
								}
								catch(err) {
									window.document.getElementById("container").innerHTML = "failed to parse message from react-native " + err;
									return;
								}

								//trigger callback
								if (message.args && callbacks[message.msgId]) {
									if (message.isSuccessfull) {
										callbacks[message.msgId].onsuccess.apply(null, message.args);
									}
									else {
										callbacks[message.msgId].onerror.apply(null, message.args);
									}
									delete callbacks[message.msgId];
								}

							});
						};

						init();
					}());
					        window.counter = 0;
						    function clickHandler(obj) {
                  //console.log("objnew", obj);
						        window.webViewBridge.send('handleDataReceived', obj, function(res) {
						            //window.document.getElementById("button").setAttribute("style", "background-color: " + res);
						        }, function(err) {
									//window.document.getElementById("container").setAttribute("style", "background-color: " + err);
						        });
						    }
          function resizeIFrameToFitContent() {
            var iFrame = document.getElementById( 'kingspage' );
            iFrame.width  = iFrame.contentWindow.document.body.scrollWidth;
            iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
        }
        
        /*
        window.addEventListener('DOMContentLoaded', function(e) {
        
            var iFrame = document.getElementById( 'kingspage' );
            resizeIFrameToFitContent( iFrame );
        
            // or, to resize all iframes:
            
            var iframes = document.querySelectorAll("iframe");
            for( var i = 0; i < iframes.length; i++) {
                resizeIFrameToFitContent( iframes[i] );
            }
            
        } );
        */
                
  const handleFacebook = (e) => {
    e.preventDefault();
    dispatch(facebookSignIn());
  }

  
  const handleIframe = (e) => {
    setLoading(false);
  }
  const handleKingsChatx = (e) => {
    e.preventDefault();
    window.ReactNativeWebView.postMessage("testing");
  }

  const handleKingsChat = (e) => {
    e.preventDefault();
    setLoading(true);
    /*
    dispatch(kingschatwebSign());
    
    kingschatwebSign().then(res => {
      console.log(res);
    });
    */
    const loginOptions = {
      // Scopes is an Array of scopes you want access
      scopes: ["user"], // ex. ["send_chat_message"]
      // Your clientId generated on KingsChat's Developer Site
      //clientId: "c666876e-6714-46f6-90d3-a6b3cffc6688" // ex. 'a1234567-abcd-1234-abcd-12345abc1234'
      clientId: "e94ee580-da05-47e5-89c9-e9f43c9ca77a"
    }
    //console.log("loginoptions", loginOptions);
      kingsChatWebSdk.login(loginOptions)
            .then(async authenticationTokenResponse => {
              authenticationTokenResponse['web'] = true;
              console.log("tokenResponse", JSON.stringify(authenticationTokenResponse));
              let url = `https://jekalo.com/kingschatweb.php`;
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(authenticationTokenResponse)
              })
          const res = await response.json();
          //console.log("newres", JSON.stringify(res));
          clickHandler(res);
          //return res;
          //dispatch(SignInToken(res));
      });
    
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' });
    if (auth.error.flag) {
      setData({...data,email:'',pass:''});
      dispatch(clearLoginError());
    }
  };

  const onInputChange = (event) => {
    setData({ ...data, [event.target.id]: event.target.value })
  }

  const handleGetOTP = (e) => {
    e.preventDefault();
    if(data.country){
      if(data.contact){
        if (isNaN(data.contact)) {
          setData({...data, entryType: 'email'});
          const re =/^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if(re.test(data.contact)){
              setOtpCalled(true);
              dispatch(requestEmailOtp(data.contact));
          }else{
              setCommonAlert({ open: true, msg: t('proper_email')})
          }
        } else{
          setData({...data, entryType: 'mobile'});
          let formattedNum = data.contact.replace(/ /g, '');
          if (formattedNum.length > 6) {
            const phoneNumber = "+" + data.country + formattedNum;
            const appVerifier = window.recaptchaVerifier;
            authRef
              .signInWithPhoneNumber(phoneNumber, appVerifier)
              .then(res => {
                  setData({...data, verificationId: res.verificationId})
              })
              .catch(error => {
                  setCommonAlert({ open: true, msg: error.code + ": " + error.message})
              });
          } else {
              setCommonAlert({ open: true, msg: t('mobile_no_blank_error')})
          }
        }
      }else{
        setCommonAlert({ open: true, msg: t('contact_input_error')})
      }
    }else{
      setCommonAlert({ open: true, msg: t('country_blank_error')})
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //eslint-disable-next-line
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data.email) && data.pass.length > 0) {
      dispatch(signIn(data.email, data.pass));
    } else {
      setCommonAlert({ open: true, msg: t('login_validate_error')})
    }
    setData({...data,email:'',pass:''});
  }

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    if(data.otp && data.otp.length === 6){
      if(data.entryType === 'email'){
          dispatch(verifyEmailOtp(
              data.contact,
              data.otp
          ));
      }else{
          dispatch(mobileSignIn(
              data.verificationId,
              data.otp
          ));
      }
    }else{
      setCommonAlert({ open: true, msg: t('otp_validate_error')})
    }
  }

  const handleCancel = (e) => {
    setData({
      ...data,
      contact: null,
      verificationId: null
    });
  }

  const onCountryChange = (object, value) => {
    if (value && value.phone) {
      setData({ ...data, country: value.phone, selectedcountry:value });
    }
  };

  return (
    <div>
      
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div id="sign-in-button" />
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
              
                <CardBody>
                <Button className={classes.normalButtonKings} size="lg" onClick={handleKingsChat}>
                Continue with KingsChatWeb
                    </Button>
                    {loading ? <CircularLoading /> : null}

                    <iframe src="" onLoad={handleIframe} name="kingspage" id="kingspage"  style={{height:"500px",width:"100%",border:"none",overflow:"hidden"}}></iframe>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
       
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
      </div>
    </div>
  );
}