export const FirebaseConfig = {
	"projectId": "callaride2022",
	"appId": "1:654838455281:web:1af5f533ec23330ce3c250",
	"databaseURL": "https://callaride2022-default-rtdb.firebaseio.com",
	"storageBucket": "callaride2022.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyB--_3pibrSJWH0OCz6WrDCKzyxRwrfD_k",
	"authDomain": "callaride2022.firebaseapp.com",
	"messagingSenderId": "654838455281",
	"measurementId": "G-FMLPW53MZF"
};